






































































































































































































































































































































import Vue, { VNode } from 'vue';
import { mapActions, mapGetters, mapState } from 'vuex';
import formatMixin from '@/mixins/formatMixin';
import { difference } from '@/api/difference';
import { isEmpty } from 'lodash';
import gsap from 'gsap';
import colors from 'vuetify/es5/util/colors';
import SupplierSelect from '@/components/Document/Form/SupplierSelect.vue';
import ProductSelect from '@/components/Document/Form/ProductSelect.vue';
import MeasurementSelect from '@/components/Document/Form/MeasurementSelect.vue';
import ChartOfAccountSelect from '@/components/Document/Form/ChartOfAccountSelect.vue';
import WhoPaysSelect from '@/components/Document/Form/WhoPaysSelect.vue';
import Date from '@/components/Common/Date.vue';
import DocumentVersionNav from '@/components/Document/DocumentVersionNav.vue';
import Document from '@/models/Document/Document';
import { VuetifyGoToOptions } from 'vuetify';
import moment from 'moment';
import Bowser from 'bowser';
import {
    log
} from '@/helpers/ConsoleLogHelper';

export default Vue.extend({
  name: 'DocumentForm',
  mixins: [formatMixin],
  data: () => ({
    valid: true,
    isTotalFocused: false,    
    isAmountFocused: false
  }),
  components: {
    DocumentVersionNav,
    SupplierSelect,
    ProductSelect,
    MeasurementSelect,
    ChartOfAccountSelect,
    WhoPaysSelect,
    Date
  },
  props: ['document', 'disabled', 'formHeight', 'panelHeight', 'top','vh','isSafari'],
  computed: {
    ...mapState({
      userProfile: (state: any) => state.userProfile.userProfile
    }),
    ...mapGetters({
      getProjectById: 'projects/byId',
      isUserManager: 'projects/isManager',
      isUserApprover: 'projects/isApprover',
      isUserViewer: 'projects/isViewer'
    }),
    serviceDate(): number { 
        log (moment().utcOffset());
        log (this.document.serviceDate);
        let stdTimezoneOffset = function (date: Date) {
          var jan = new window.Date(date.getFullYear(), 0, 1);
          var jul = new window.Date(date.getFullYear(), 6, 1);
          return Math.max(jan.getTimezoneOffset(), jul.getTimezoneOffset());
        }
        let isDstObserved = function (date: Date) {
            return date.getTimezoneOffset() < stdTimezoneOffset(date);
        }
        let today = new window.Date();
        let dst = 0;
        if (isDstObserved(today)) { 
            dst = 1;
        }        
        return moment.utc(this.document.serviceDate + (-1 * moment().utcOffset() * 60 * 1000) + (dst * 3600*1000)).valueOf();
    },
    parseTotal(): string {
      return parseFloat(this.document.total.replace(/,/g,'')).toFixed(2);
    },
    parseAmount(): string {
        let amount = this.document.amount;
        if (!amount) amount = '0.00';
        return parseFloat(amount.replace(/,/g,'')).toFixed(2);
    },
    parseUnitcost(): string {
      return parseFloat(this.document.perUnit.replace(/,/g,'')).toFixed(2);
    },
    parseTax(): string {
        return parseFloat(this.document.tax.replace(/,/g,'')).toFixed(2);
    },
    parseTip(): string {
      return parseFloat(this.document.tip.replace(/,/g,'')).toFixed(2);
    },
    project() {
      return this.getProjectById(""+this.document.projectId);
    },
    isDisabledServiceDate(): boolean {
      /*if (this.document != null) {
        return (this.document! as Document).id !== (this.document! as Document).masterId;
    }*/
      return false;
    },
    ticketLabel(){
        return (this.userProfile.mode == 'CH')?'Who Pays':'Details';
    },
    showMeasurement() {
        log('Mode = '+this.userProfile.mode);
        return (this.userProfile.mode == 'CH')?false:true;
    },
    showDepositNumber() {
        log('Mode = '+this.userProfile.mode);
        return (this.userProfile.mode == 'CH')?true:false;
    }
  },
  methods: {
    handleChange(ref: string, value: any)  {
        let taxRate:number = 0.05; // 5 percent
        let perUnit:number = this.document.perUnit?parseFloat(this.document.perUnit.replace(/,/g,'')):0;
        let amount:number = this.document.amount?parseFloat(this.document.amount.replace(/,/g,'')):0;
        let tax:number = this.document.tax?parseFloat(this.document.tax.replace(/,/g,'')):0;
        let tip:number = this.document.tip?parseFloat(this.document.tip.replace(/,/g,'')):0;
        let total:number = this.document.total?parseFloat(this.document.total.replace(/,/g,'')):0;
        let units:number = parseFloat(this.document.units);

        if (this.document.units < 1) {
            this.document.units = "1";
            units = 1;
        }

        if (ref === "units") {
          perUnit = amount / Math.max(units,1);//Prevent division by zero...        
        }

        if (ref === "perUnit") {
          amount = perUnit*units;        
          tax = amount * taxRate;          
          total = amount+tax+tip;        
        }

        if (ref === "amount") {
          perUnit = amount/Math.max(units,1);;
          tax = amount * taxRate;        
          total = amount+tax+tip;        
        }

        if (ref === "tax") {
          amount = total - tip - tax;
          perUnit = amount/Math.max(units,1);
        }

        if (ref === "tip") {
          amount = (total - tip)/(1+taxRate);
          tax = amount * taxRate;        
          perUnit = amount/Math.max(units,1);
        }

        if (ref === "total") {          
          units = 1;
          perUnit = amount = (total-tip)/(1+taxRate);
          tax = total/((1/taxRate)+1);
        }

        this.document.units = parseFloat(""+((units)?units:"0.00")).toLocaleString('en-CA', { minimumFractionDigits: 2, maximumFractionDigits: 2});
        this.document.perUnit = parseFloat(""+((perUnit)?perUnit:"0.00")).toLocaleString('en-CA', { minimumFractionDigits: 2, maximumFractionDigits: 2});
        this.document.tax = parseFloat(""+((tax)?tax:"0.00")).toLocaleString('en-CA', { minimumFractionDigits: 2, maximumFractionDigits: 2});
        this.document.tip = (tip)?parseFloat(""+tip).toLocaleString('en-CA', { minimumFractionDigits: 2, maximumFractionDigits: 2}):"";
        this.document.total = parseFloat(""+((total)?total:"0.00")).toLocaleString('en-CA', { minimumFractionDigits: 2, maximumFractionDigits: 2});
        this.document.amount = parseFloat(""+((amount)?amount:"0.00")).toLocaleString('en-CA', { minimumFractionDigits: 2, maximumFractionDigits: 2});
        log("handleChange("+ref+")");
        log(this.document);
    },
    handleFocus(ref: string) {
      if (ref === 'total') {
        this.isTotalFocused = true;
      }
      this.$emit('handleIsFormFocused', true);
      this.$emit('closePicturePanel');
    },
    handleBlur(ref: string) {      
      if (ref === 'total') {
        this.isTotalFocused = false;
      }
    },
    handleChangeTotal(input: any) {
    },
    handleRedFlagClick() {
      this.document.verified = true;
    },
    onlyNumber(event: any) {
      let keyCode = event.keyCode ? event.keyCode : event.which;
      if ((keyCode < 48 || keyCode > 57) && keyCode !== 46) {
        // 46 is dot
        event.preventDefault();
      }
    },
    handleServiceDateChange(date: string) {
      const newDate = moment(date)
        .utc()
        .toDate();
      this.document.serviceDate = newDate.getTime();
  }
  }
});
