















import Vue from 'vue';
import { ResolutionNames, Resolutions } from '@/models/Resolutions';

export default Vue.extend({
  name: 'VideoCamera',
  data: () => ({}),
  props: ['isCameraActivated', 'resolution'],
  components: {},
  computed: {},
  watch: {
    isCameraActivated: {
      handler(newValue) {
        if (newValue) {
          this.activateCamera();
        } else {
          this.deactivateCamera();
        }
      },
      immediate: true
    }
  },
  methods: {
    handleTakePictureClick() {
      this.$emit('handleTakePictureClick');
      this.deactivateCamera();
    },

    activateCamera() {
      let constraints = this.getConstraints();
      (constraints as any).video.facingMode = 'environment';
      const video = this.$refs.video as any;
      if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
        navigator.mediaDevices.getUserMedia(constraints).then((stream) => {
          video.srcObject = stream;
        });
      }
    },

    deactivateCamera() {
      this.$emit('handleDeActivateCamera');
      const video = this.$refs.video as any;
      if (video) {
        video.srcObject.getVideoTracks().forEach((track: any) => track.stop());
      }
    },
    getConstraints() {
      switch (this.resolution) {
        case ResolutionNames.QVGA:
          return {
            video: {
              width: { ideal: (Resolutions as any).get(ResolutionNames.QVGA).width },
              height: { ideal: (Resolutions as any).get(ResolutionNames.QVGA).height }
            }
          };
        case ResolutionNames.VGA:
          return {
            video: {
              width: { ideal: (Resolutions as any).get(ResolutionNames.VGA).width },
              height: { ideal: (Resolutions as any).get(ResolutionNames.VGA).height }
            }
          };
        case ResolutionNames.HD:
          return {
            video: {
              width: { ideal: (Resolutions as any).get(ResolutionNames.HD).width },
              height: { ideal: (Resolutions as any).get(ResolutionNames.HD).height }
            }
          };
        case ResolutionNames.FULLHD:
          return {
            video: {
              width: { ideal: (Resolutions as any).get(ResolutionNames.FULLHD).width },
              height: { ideal: (Resolutions as any).get(ResolutionNames.FULLHD).height }
            }
          };
        case ResolutionNames.FOURK:
          return {
            video: {
              width: { ideal: (Resolutions as any).get(ResolutionNames.FOURK).width },
              height: { ideal: (Resolutions as any).get(ResolutionNames.FOURK).height }
            }
          };
        case ResolutionNames.EIGHTK:
          return {
            video: {
              width: { ideal: (Resolutions as any).get(ResolutionNames.EIGHTK).width },
              height: { ideal: (Resolutions as any).get(ResolutionNames.EIGHTK).height }
            }
          };
      }
    }
  }
});
