

















import Vue from 'vue';
import { mapActions, mapGetters, mapState } from 'vuex';
import PictureFormField from '@/components/Common/PictureFormField.vue';
import DocumentLayouts from '@/models/DocumentLayouts';
export default Vue.extend({
  name: 'PicturesPanel',
  data: () => ({}),

  watch: {},
  props: ['documentId', 'timestamp', 'disabled', 'panelHeight', 'top'],
  components: {
    PictureFormField
  },
  computed: {
    ...mapState({
      userSettings: (state: any) => state.userProfile.settings
    }),
    expandPanelNb() {
      return this.userSettings.documentLayout === DocumentLayouts.split ? 0 : -1;
    }
  },
  methods: {
    handleIsPicturePanelFocused() {
      this.$emit('handleIsPicturePanelFocused');
    }
  }
});
