


















import Vue from 'vue';
import { mapState, mapGetters, mapActions } from 'vuex';
import Document from '@/models/Document/Document';
import DateTime from '@/components/Common/DateTime.vue';
import { DocumentVersionNavigation } from '@/models/Document/DocumentVersionNavigation';
export default Vue.extend({
  name: 'DocumentVersionNav',
  data: () => ({}),
  props: ['disabled'],
  components: {
    DateTime
  },

  computed: {
    ...mapGetters({
      getVersionNav: 'documents/versionNav',
      getDocumentById: 'documents/byId',
      getDocumentVersion: 'documents/version',
    }),
    document(): Document {
      let document;
      const { documentId, timestamp } = this.$route.params;
      if (documentId && timestamp) {
        document = this.getDocumentVersion(documentId, parseInt(timestamp));
      } else {
        document = this.getDocumentById(documentId);
      }
      return document;
    },
    getChangeDate(): number {
        return (String(this.document.date).length <= 10)? Number(this.document.date)*1000:this.document.date;
    },

    navigation(): DocumentVersionNavigation {
      return this.getVersionNav(this.$route.params.documentId, parseInt(this.$route.params.timestamp));
    }
  },

  methods: {
    handleRightArrowIconClick(): void {
      if (this.navigation && (this.navigation as DocumentVersionNavigation).next) {
        const { id, timestamp } = (this.navigation as DocumentVersionNavigation).next;
        this.$router.push({
          name: 'Document',
          params: {
            documentId: id,
            timestamp: timestamp.toString()
          }
        });
      }
    },

    handleLeftArrowIconClick(): void {
      if (this.navigation && (this.navigation as DocumentVersionNavigation).previous) {
        const { id, timestamp } = (this.navigation as DocumentVersionNavigation).previous;
        this.$router.push({
          name: 'Document',
          params: {
            documentId: id,
            timestamp: timestamp.toString()
          }
        });
      }
    }
  }
});
