export const heights = {
  xs: {
    split: {
      focus: {
        ios_safari: {
          panel: 40,
          form: 60
        },
        other: {
          panel: 25,
          form: 55
        }
      },
      blur: {
        ios_safari: {
          panel: 50,
          form: 30
        },
        other: {
          panel: 60,
          form: 30
        }
      }
    },
    full: {
      focus: {
        ios_safari: {
          panel: 0,
          form: 90
        },
        other: {
          panel: 0,
          form: 55
        }
      },
      blur: {
        ios_safari: {
          panel: 0,
          form: 80
        },
        other: {
          panel: 0,
          form: 90
        }
      }
    }
  },
  other: {
    split: {
      focus: {
        // ios_safari: {
        //   // panel: 40,
        //   // form: 60
        // },
        other: {
          panel: 44.5,
          form: 44.5
        }
      },
      blur: {
        // ios_safari: {
        //   panel: 50,
        //   form: 30
        // },
        other: {
          panel: 44.5,
          form: 44.5
        }
      }
    },
    full: {
      focus: {
        // ios_safari: {
        //   panel: 0,
        //   form: 90
        // },
        other: {
          panel: 0,
          form: 90
        }
      },
      blur: {
        // ios_safari: {
        //   panel: 0,
        //   form: 80
        // },
        other: {
          panel: 0,
          form: 90
        }
      }
    }
  }
};

export type BreakpointType = 'xs';
export type FocusType = 'focus' | 'blur';
export type OsType = 'ios_safari' | 'other';
// heights:
//   //md
//   panel_initial_md: 44.5,
//   panel_isformfocused_md: 22.25,
//   form_initial_md: 44.5,
//   form_isformfocused_md: 66.75
// }
