<template>
  <draggable :list="pictures" @end="handleMoveEnd" tag="div" class="layout row wrap" handle=".handle">
    <v-flex v-for="(picture, index) in pictures" :key="index" xs6 sm6 md5 pa-1>
      <PictureCard
        :picture="picture"
        :index="index + 1"
        :total="pictures.length"
        @handleDeletePicture="$emit('handleDeletePicture', picture)"
        @handleToggleShowAllPictures="$emit('handleToggleShowAllPictures')"
        @handleShowImgWithZoom="$emit('handleShowImgWithZoom', picture)"
        :disabled="disabled"
      />
    </v-flex>
  </draggable>
</template>

<script>
import PictureCard from '@/components/Common/PictureCard';
import draggable from 'vuedraggable';
export default {
  data: () => ({}),
  name: 'PictureCards',
  props: ['pictures', 'disabled'],
  components: {
    PictureCard,
    draggable
  },
  computed: {},
  methods: {
    handleMoveEnd(evt) {
      this.$emit('handleMoveEnd', evt);
    }
  }
};
</script>

<style scoped></style>
