

































import Vue from 'vue';
import VideoCamera from './VideoCamera.vue';
import FileInputCamera from './FileInputCamera.vue';
import FullScreenImage from './FullScreenImage.vue';
import { isFileInputCamera } from '@/helpers/browserHelper';

enum PreviewTypes {
  NONE = '',
  FULL = 'FULL',
  THUMB = 'THUMB'
}
interface CameraData {
  isShowFullScreen: boolean;
  canvas: HTMLCanvasElement;
}

export default Vue.extend({
  name: 'Camera',
  data: (): CameraData => ({
    isShowFullScreen: false,
    canvas: document.createElement('canvas')
  }),

  props: ['disabled', 'isCameraActivated', 'previewType', 'resolution', 'quality'],

  components: {
    VideoCamera,
    FileInputCamera,
    FullScreenImage
  },
  computed: {
    isFileInputCamera(): boolean {
      return isFileInputCamera();
    }
  },

  methods: {
    handleDeActivateCamera() {
      this.$emit('handleDeActivateCamera');
    },

    handleTakePicture(imgSrc: string) {
      this.$emit('handleTakePicture', imgSrc);
    },

    handleTakePictureClick() {
      this.$emit('handleTakePictureClick');

      if (this.previewType === PreviewTypes.FULL) {
        const cameraView = (this.$refs.videoCamera as any).$refs.video as any;
        this.canvas.width = cameraView.videoWidth;
        this.canvas.height = cameraView.videoHeight;
        this.canvas.getContext('2d')!.drawImage(cameraView, 0, 0);

        this.isShowFullScreen = true;
      }
    },

    handleAccept() {
      this.isShowFullScreen = false;
      const imgSrc = this.canvas.toDataURL('image/jpeg', parseFloat(this.quality));
      this.$emit('handleAccept', imgSrc);
      this.canvas = document.createElement('canvas');
      this.$emit('handleDeActivateCamera');
    },

    handleRetake() {
      this.isShowFullScreen = false;
      this.canvas = document.createElement('canvas');
      this.$emit('handleRetake');
    },

    handleNext() {
      this.isShowFullScreen = false;
      const imgSrc = this.canvas.toDataURL('image/jpeg', parseFloat(this.quality));
      this.$emit('handleNext', imgSrc);
      this.canvas = document.createElement('canvas');
      this.$emit('handleDeActivateCamera');
    }
  }
});
