
















































import Vue from 'vue';
import { mapState, mapGetters, mapActions } from 'vuex';
import nanoid from 'nanoid';
import draftMixin from '@/mixins/draftMixin';
import Document from '@/models/Document/Document';
import Picture from '@/models/Picture/Picture';
import DocumentForm from '@/components/Document/Form/DocumentForm.vue';
import PicturesPanel from '@/components/Document/Form/PicturesPanel.vue';
import { difference } from '@/api/difference';
import { isEmpty } from 'lodash';
import { difference as diff } from '@/helpers/ArrayHelper';
import DocumentFactory from '@/models/Document/DocumentFactory';
import SendButton from '@/components/Document/SendButton.vue';
import { sendDocument } from '@/helpers/SendHelper';
import CameraWrapper from '@/components/Common/CameraWrapper.vue';
import { isFileInputCamera } from '@/helpers/browserHelper';
import pictureFactory from '@/models/Picture/PictureFactory';
import { heights, BreakpointType, FocusType, OsType } from '@/components/Document/Heights';
import DocumentLayouts from '@/models/DocumentLayouts';
import {
    log
} from '@/helpers/ConsoleLogHelper';

export default Vue.extend({
  name: 'Document',

  data: () => ({
    savedWithVerify: false,
    savedWithSend: false,
    isChanged: false,
    isFormFocused: false,
    watchRunning: false,
    isCameraActivated: false
  }),
  components: {
    DocumentForm,
    PicturesPanel,
    SendButton,
    CameraWrapper
  },
  mixins: [draftMixin],
  props: ['documentId', 'timestamp', 'imgSrc', 'vh', 'isSafari'],
  async created() {
    await this.handleRefresh();
    const base = this.getDocumentById(this.documentId);
    this.baseDocument = JSON.parse(JSON.stringify(base));
  },
  mounted() {
    if (this.imgSrc) {
      this.handleCreatePicture(this.imgSrc);
    } else if (this.document && this.document.isNew === true) {
      this.handleActivateCamera();
    }
  },

  watch: {
    document: {
      async handler(newValue) {
        if (this.watchRunning) return;
        this.watchRunning = true;
        if (
          this.documentId === this.baseDocument.id &&
          this.isLatestVersion(this.documentId, parseInt(this.timestamp))
        ) {
          const diffFields = difference(newValue, this.baseDocument, 'date');

          if (!isEmpty(diffFields)) {
            this.isChanged = true;
            this.baseDocument = JSON.parse(JSON.stringify(this.document));
            if (this.document.masterId === '') {
              const newId = await this.createDraft(this.document);
              this.$router.push({ name: 'Document', params: { documentId: newId } });
            } else {
              //this.updateDocument({ document: this.document, verified: diffFields.verified });
            }
          }
        }
        this.watchRunning = false;
      },
      deep: true
    }
  },

  beforeDestroy() {
    if (!this.savedWithVerify && !this.savedWithSend && this.isChanged) {
      this.handleSave();
    }
  },

  computed: {
    ...mapState({
      userSettings: (state: any) => state.userProfile.settings,
      userProfile: (state: any) => state.userProfile.userProfile
    }),
    ...mapGetters({
      getDocumentById: 'documents/byId',
      getPicturesByDocumentId: 'pictures/byDocumentId',
      getDocumentVersion: 'documents/version',
      isLatestVersion: 'documents/isLatestVersion'
    }),
    isShowBottomSheet() {
      if (this.$vuetify.breakpoint.name === 'xs' && this.isFormFocused) {
        return true;
      }
      return true;
    },
    document(): Document {
      return this.getDocumentVersion(this.documentId, parseInt(this.timestamp));
    },

    panelHeight(): number {
      let unit = this.vh;
      const breakpoint: string = this.$vuetify.breakpoint.name === 'xs' ? 'xs' : 'other';
      const layout: string = this.userSettings.documentLayout;
      const focus: string = this.isFormFocused ? 'focus' : 'blur';
      const os: string = this.isSafari ? 'ios_safari' : 'other';

      const result =
        heights[breakpoint as BreakpointType][layout as keyof typeof DocumentLayouts][focus as FocusType][os as OsType][
          'panel'
        ];

      return unit * result;
    },

    formHeight(): number {
      let unit = this.vh;
      const breakpoint: string = this.$vuetify.breakpoint.name === 'xs' ? 'xs' : 'other';
      const layout: string = this.userSettings.documentLayout;
      const focus: string = this.isFormFocused ? 'focus' : 'blur';
      const os: string = this.isSafari ? 'ios_safari' : 'other';

      const result = heights[breakpoint as BreakpointType][layout as keyof typeof DocumentLayouts][focus as FocusType][os as OsType][
          'form'
        ];
      return unit * result;
    },

    disabled(): boolean {
      if (this.isLatestVersion(this.documentId, parseInt(this.timestamp))) {
          log('Form is not disabled.');
        return false;
      }
      log('Form IS disabled.');
      return true;
    }
  },

  methods: {
    ...(mapActions({
      saveDocument: 'documents/save',
      savePicture: 'pictures/save',
      addPicture: 'pictures/add',
      createDraft: 'documents/createDraft',
      updateDocument: 'documents/update',
      getAllDocuments: 'documents/getAll',
      getAllPictures: 'pictures/getAll',
      getAllProducts: 'products/getAll',
      getAllSuppliers: 'suppliers/getAll',
      getAllMeasurements: 'measurements/getAll',
      getAllCoas: 'coas/getAll'
    }) as any),

    handleIsFormFocused(isFocus: boolean) {
      this.isFormFocused = isFocus;
    },
    handleIsPicturePanelFocused() {
      this.isFormFocused = false;
    },
    handleSave(): void {
      const pictures = this.getPicturesByDocumentId(this.documentId);
      pictures.forEach((p: Picture) => {
        this.savePicture(p);
      });
      const d = this.getDocumentVersion(this.documentId, parseInt(this.timestamp));
      const document = {
        ...d,
        isNew: false,
        verified: d.verified || this.savedWithVerify
      };
      return this.saveDocument({ document });
    },

    handleVerify() {
      if ((this.$refs.documentForm.$refs.form as Vue & { validate: () => boolean }).validate()) {
        this.savedWithVerify = true;
        this.handleSave();
        this.$router.push({ name: 'Documents' });
      }
    },
    async handleSend() {
      if ((this.$refs.documentForm.$refs.form as Vue & { validate: () => boolean }).validate()) {
        await this.handleSave();
        this.savedWithSend = true;
        sendDocument(this.document);
        this.$router.push({ name: 'Documents' });
      }
    },
    handleRefresh() {
      return Promise.all([
        this.getAllDocuments(),
        this.getAllPictures(),
        this.getAllProducts(),
        this.getAllSuppliers(),
        this.getAllMeasurements(),
        this.getAllCoas()
      ]);
    },
    handleActivateCamera() {
      this.isCameraActivated = true;
      if (isFileInputCamera()) {
        const input = (this.$refs.wrapper as any).$refs.camera.$refs.inputCamera.$refs.fileInput as HTMLInputElement;
        input.click();
      }
    },
    handleDeActivateCamera() {
      this.isCameraActivated = false;
    },
    handleCreatePicture(imgSrc: string) {
      const picture = pictureFactory.createPicture(this.document.projectId, this.userProfile.id, imgSrc);
      this.addPicture({ document: this.document, picture: picture });
    }
  }
});
