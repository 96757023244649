





















import Vue from 'vue';
import { mapActions, mapGetters, mapState } from 'vuex';
import Product from '@/models/Product/Product';
import ClientoDialogSelect from '@valtrem/cliento-vuetify-dialog-select/src/components/DialogSelect.vue';

export default Vue.extend({
  name: 'ProductSelect',
  data: () => ({
    query: '',
    projectProducts: []
  }),

  props: {
    value: {
      type: String
    },
    projectId: {
      type: String
    },
    disabled: {
      type: Boolean
    }
  },
  components: {
    ClientoDialogSelect
  },
  async created() {
    await this.getProductsFromServerByProjectId(this.projectId);
    this.setProjectProducts();
  },
  watch: {
    projectId: {
      async handler(newValue) {
        await this.getProductsFromServerByProjectId(newValue);
        this.setProjectProducts();
      }
    }
  },
  computed: {
    ...mapGetters({
      getAllProducts: 'products/all',
      getProductsByProjectId: 'products/byProjectId'
    }),

    products(): string[] {
      const results = this.projectProducts
        .map((p: Product) => p.title.toLowerCase())
        .filter((s: string) => {
          s = s.toLowerCase();
          return s.indexOf(this.query) > -1;
        });
      return this.getAllProducts().map((p: Product) => p.title);
      /*
      if (this.query && results.length === 0) {
        return this.getAllProducts().map((p: Product) => p.title);
      } else {
        return this.projectProducts.map((p: Product) => p.title);
    }*/
    }
  },

  methods: {
    ...mapActions({
      getProductsFromServerByProjectId: 'products/getFromServerByProjectId'
    }),

    setProjectProducts() {
      this.projectProducts = this.getProductsByProjectId(this.projectId);
    },
    handleUpdateQuery(query: string) {
      this.query = query;
    }
  }
});
