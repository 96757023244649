






































import Vue from 'vue';
import { mapActions, mapState, mapGetters } from 'vuex';
import CameraButton from '@/components/Common/Camera/CameraButton.vue';
import PictureComponentMode from '@/models/PictureComponentMode';
import ImgWithZoom2 from '@/components/Common/ImgWithZoom2.vue';
import PictureCards from '@/components/Common/PictureCards.vue';
import PictureCard from '@/components/Common/PictureCard.vue';
import Picture from '@/models/Picture/Picture';
import Document from '@/models/Document/Document';

export default Vue.extend({
  name: 'PictureFormField',
  data: () => ({
    pictureComponentMode: PictureComponentMode.FIRST,
    picToZoom: null as Picture | null
  }),
  components: {
    PictureCards,
    PictureCard,
    ImgWithZoom2,
    CameraButton
  },
  props: ['documentId', 'timestamp', 'disabled', 'isCameraActivated'],
  mounted() {
    if (this.pictures && this.pictures.length === 1) {
      this.handleShowImgWithZoom(this.pictures[0]);
    } else {
      this.handleExitImgWithZoom();
    }
  },
  watch: {
    $route(to, from) {
      this.pictureComponentMode = PictureComponentMode.FIRST;
      this.picToZoom = null;
    }
  },
  computed: {
    ...mapState({
      userProfile: (state: any) => state.userProfile.userProfile
    }),
    ...mapGetters({
      getPicturesByDocumentId: 'pictures/byDocumentId',
      getPicturesByDocumentVersion: 'pictures/byDocumentVersion',
      getDocumentById: 'documents/byId',
      getDocumentVersion: 'documents/version'
    }),
    pictures(): Picture[] {
      if (this.timestamp && this.documentId) {
        return this.getPicturesByDocumentVersion(this.documentId, parseInt(this.timestamp));
      } else {
        return this.getPicturesByDocumentId(this.documentId);
      }
    },

    document(): Document {
      let document;
      if (this.documentId && this.timestamp) {
        document = this.getDocumentVersion(this.documentId, parseInt(this.timestamp));
      } else {
        document = this.getDocumentById(this.documentId);
      }
      return document;
    },

    modes(): any {
      return PictureComponentMode;
    }
  },

  methods: {
    ...mapActions({
      removePicture: 'pictures/remove'
    }),

    handleActivateCamera(): void {
      this.$emit('handleActivateCamera');
      this.picToZoom = null;
    },

    handleShowImgWithZoom(picture: Picture) {
      this.pictureComponentMode = PictureComponentMode.ZOOM;
      this.picToZoom = picture;
    },

    handleExitImgWithZoom() {
      this.pictureComponentMode = PictureComponentMode.ALL;
      this.picToZoom = null;
    },

    handleToggleShowAllPictures() {
      if (this.pictureComponentMode === PictureComponentMode.ALL) {
        this.pictureComponentMode = PictureComponentMode.FIRST;
      } else {
        this.pictureComponentMode = PictureComponentMode.ALL;
      }
    },

    handleDeletePicture(picture: Picture) {
      const ok = confirm('Are you sure you want to delete this item?');
      if (ok === true) {
        this.removePicture({ document: this.document, picture: picture });
      }
    }
  }
});
