










import Vue from 'vue';
import moment from 'moment';
import formatMixin from '@/mixins/formatMixin';
import Date from '@/components/Common/Date.vue';
import Time from '@/components/Common/Time.vue';
export default Vue.extend({
  name: 'DateTime',
  data: () => ({
    dateString: '',
    timeString: ''
  }),
  mixins: [formatMixin],
  components: { Date, Time },
  props: ['value', 'disabled', 'label'],
  watch: {
    value: {
      handler(newValue: number) {
        if (newValue !== 0) {
          const date = moment(newValue).toDate();
          this.dateString = date.toDateString();
          this.timeString = date.toTimeString();
        }
      },
      immediate: true
    }
  },
  methods: {
    handleTimeChange(time: string) {
      const s = time.split(':');
      const date = moment(this.value)
        .set({ hour: parseInt(s[0]), minute: parseInt(s[1]) })
        .utc()
        .toDate();

      this.$emit('input', date.getTime());
    },
    handleDateChange(date: string) {
      const newDate = moment(date)
        .utc()
        .toDate();

      this.$emit('input', newDate.getTime());
    }
  }
});
