





















import Vue from 'vue';
import { mapActions, mapGetters, mapState } from 'vuex';
import Supplier from '@/models/Supplier/Supplier';
import ClientoDialogSelect from '@valtrem/cliento-vuetify-dialog-select/src/components/DialogSelect.vue';
export default Vue.extend({
  name: 'SupplierSelect',
  data: () => ({
    query: '',
    projectSuppliers: []
  }),

  props: {
    value: {
      type: String
    },
    projectId: {
      type: String
    },
    disabled: {
      type: Boolean
    }
  },
  components: {
    ClientoDialogSelect
  },
  async created() {
    await this.getSuppliersFromServerByProjectId(this.projectId);
    this.setProjectSuppliers();
  },
  watch: {
    projectId: {
      async handler(newValue) {
        await this.getSuppliersFromServerByProjectId(newValue);
        this.setProjectSuppliers();
      }
    }
  },

  computed: {
    ...mapGetters({
      getAllSuppliers: 'suppliers/all',
      getSuppliersByProjectId: 'suppliers/byProjectId'
    }),
    suppliers(): string[] {
      const results = this.projectSuppliers
        .map((p: Supplier) => p.title.toLowerCase())
        .filter((s: string) => {
          s = s.toLowerCase();
          return s.indexOf(this.query) > -1;
        });
      return this.getAllSuppliers().map((p: Supplier) => p.title);
      /*if (this.query && results.length === 0) {
        return this.getAllSuppliers().map((p: Supplier) => p.title);
      } else {
        return this.projectSuppliers.map((p: Supplier) => p.title);
    }*/
    }
  },

  methods: {
    ...mapActions({
      getSuppliersFromServerByProjectId: 'suppliers/getFromServerByProjectId'
    }),

    setProjectSuppliers() {
      this.projectSuppliers = this.getSuppliersByProjectId(this.projectId);
    },
    handleUpdateQuery(query: string) {
      this.query = query;
    }
  }
});
