




import Vue from 'vue';
import Camera from './Camera/Camera.vue';
import {
    isFileInputCamera
} from '@/helpers/browserHelper';
import {
    log
} from '@/helpers/ConsoleLogHelper';

export default Vue.extend({
    name: 'CameraWrapper',
    data: () => ({}),
    props: ['isCameraActivated', 'imgSrc', 'disabled'],
    components: {
        Camera
    },
    methods: {
        handleAccept(imgSrc: string) {
            this.handleTakePicture(imgSrc);
        },
        handleNext(imgSrc: string) {
            this.handleTakePicture(imgSrc);
        },
        handleDeActivateCamera() {
            this.$emit('handleDeActivateCamera');
        },
        handleRetake() {
            this.$emit('handleActivateCamera');
        },
        handleTakePicture(imgSrc: string) {
            if (imgSrc) {
                let $this = this;
                let image = new Image();
                image.setAttribute('crossOrigin', 'anonymous');
                image.setAttribute('src', imgSrc);
                image.onload = function() {
                    $this.$emit('handleCreatePicture', $this.rotateLoadedImage(image, (localStorage.defaultPictureRotation) ? parseInt(localStorage.defaultPictureRotation) : 0));
                    $this.handleDeActivateCamera();
                };
            }
        },
        rotateLoadedImage(image: any, degrees: number) {
            let canvas = document.createElement('canvas');
            let context = canvas.getContext('2d');
            let newImgSrc = '';
            if (context) {
                canvas.height = Math.max(image.height,image.width); 
                canvas.width = Math.max(image.height,image.width);
                context.clearRect(0, 0, canvas.width, canvas.height);
                context.save();
                context.translate(canvas.width / 2, canvas.height / 2);
                context.rotate(degrees * (Math.PI / 180));
                context.drawImage(image, -image.width / 2, -image.height / 2);
                context.restore();
                newImgSrc = canvas.toDataURL('image/jpg');
            }
            canvas.remove();
            return newImgSrc;
        }
    }
});
