





















import Vue from 'vue';
import { mapActions, mapGetters, mapState } from 'vuex';
import ClientoDialogSelect from '@valtrem/cliento-vuetify-dialog-select/src/components/DialogSelect.vue';
import ChartOfAccount from '../../../models/ChartOfAccount/ChartOfAccount';
import {
    log
} from '@/helpers/ConsoleLogHelper';

export default Vue.extend({
  name: 'ChartOfAccountSelect',
  data: () => ({
    query: ''
  }),

  watch: {},
  props: {
    value: {
      type: String
    },
    projectId: {
      type: String
    },
    disabled: {
      type: Boolean
    }
  },
  components: {
    ClientoDialogSelect
  },
  computed: {
    ...mapGetters({
      getChartOfAccountsByProjecyId: 'coas/byProjectId'
    }),
    coas() {
      log('ChartOfAccountsPulldown projectId= '+this.projectId);
      log(this.getChartOfAccountsByProjecyId(this.projectId).map((p: ChartOfAccount) => p.title));
      /*
      const coas = this.getChartOfAccountsByProjecyId(this.projectId).map((p: ChartOfAccount) => p.title);
      // @ts-expect-error
      return coas.filter((d: String) => d.match(/^([0-9]*)/g) && parseInt(d.match(/^([0-9]*)/g)[0]) < 50000);
      */
      return this.getChartOfAccountsByProjecyId(this.projectId).map((p: ChartOfAccount) => p.title);
    }
  },
  methods: {
    handleUpdateQuery(query: string) {
      this.query = query;
    }
  }
});
