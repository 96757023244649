




















import Vue from 'vue';
export default Vue.extend({
  name: 'FullScreenPicture',
  data: () => ({
    isFailedImg: false
  }),
  props: ['canvas', 'isShowFullScreen', 'quality'],
  computed: {
    imgSrc(): string {
      return this.canvas.toDataURL('image/jpeg', parseFloat(this.quality));
    }
  },
  methods: {
    onSrcError() {
      this.isFailedImg = true;
    }
  }
});
