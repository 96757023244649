














import Vue from 'vue';
import VueZoomer from 'vue-zoomer';
Vue.use(VueZoomer);

export default Vue.extend({
  name: 'ImgWithProgress',
  data: () => ({
    isFailedImg: false
  }),
  props: ['src'],
  computed: {
    imgSrc(): void {
      return this.isFailedImg ? `${process.env.BASE_URL}img/not-found.png` : this.src;
    },
    zoomerStyle(): any {
      return {
        width: '100%',
        height: '100%'
      };
    }
  },
  methods: {
    onSrcError(event: any) {
      this.isFailedImg = true;
    }
  }
});
