






















import Vue from 'vue';
import { mapGetters } from 'vuex';
import {
    log
} from '@/helpers/ConsoleLogHelper';
import ImgWithProgress from '@/components/Common/ImgWithProgress.vue';
import PictureSizes from '@/models/Picture/PictureSizes';
import orientation from 'exif-orientation-image';
export default Vue.extend({
  name: 'PictureCard',
  data: () => ({}),
  props: ['picture', 'index', 'total', 'disabled'],
  components: {
    ImgWithProgress
  },
  computed: {
    ...mapGetters({
        //getPicturesrcByIdAndSize(picture.id, pictureSizes.MEDIUM)
      getPicturesrcByIdAndSize: 'pictures/srcByIdAndSize'
    } as any),
    pictureSizes() {
      return PictureSizes;
    }
  },
  methods: {
    handleRotatePicture() {
        //Get current device rotation setting... if any, update setting while at it.
        let defaultPictureRotation = (localStorage.defaultPictureRotation) ? parseInt(localStorage.defaultPictureRotation):0;
        defaultPictureRotation += 90;
        if (defaultPictureRotation >= 360) defaultPictureRotation = 0;
        localStorage.defaultPictureRotation = defaultPictureRotation;

        let $this = this;
        let image = new Image();
        image.setAttribute('crossOrigin', 'anonymous');
        image.setAttribute('src', this.picture.imgSrc);
        image.onload = function() {
          $this.picture.imgSrc = $this.rotateLoadedImage(image, 90);
        };
    },
    rotateLoadedImage(image: any, degrees: any) {
        log('rotateLoadedImage: image.height is ' + image.height);
        log('rotateLoadedImage: image.width is ' + image.width);
        log('rotateImage: image = ' + image);
        log('rotateImage: degrees = ' + degrees);
        let canvas = document.createElement('canvas');
        let context = canvas.getContext('2d');
        let newImgSrc = "";
        if (context) {
            canvas.height = Math.max(image.height,image.width);
            canvas.width = Math.max(image.height,image.width);
            context.clearRect(0, 0, canvas.width, canvas.height);
            context.save();
            context.translate(canvas.width / 2, canvas.height / 2);
            context.rotate(degrees * (Math.PI / 180));
            context.drawImage(image, -image.width / 2, -image.height / 2);
            context.restore();
            newImgSrc = canvas.toDataURL('image/jpg');
            log('rotateImage: newImgSrc = ' + newImgSrc);
      }
      canvas.remove();
      return newImgSrc;
 }

  }
});
