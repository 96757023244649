































import Vue from 'vue';
import moment from 'moment';
import formatMixin from '@/mixins/formatMixin';

export default Vue.extend({
  name: 'Time',
  data: () => ({
    timeMenu: false
  }),
  mixins: [formatMixin],
  props: ['value', 'disabled', 'label'],
  methods: {
    handleTimeChange(value: any) {
      this.$emit('input', value);
    },
    handleClose() {
      this.timeMenu = false;
    }
  }
});
