







import Vue from 'vue';
export default Vue.extend({
  name: 'CameraButton',
  props: ['isCameraActivated', 'disabled'],
  components: {},
  computed: {
    cameraBtnText() {
      return this.isCameraActivated ? 'Deactivate Camera' : 'Activate Camera';
    },
    cameraBtnIcon() {
      return this.isCameraActivated ? 'videocam_off' : 'videocam';
    }
  }
});
