






















import Vue from 'vue';
import { mapActions, mapGetters, mapState } from 'vuex';
import Measurement from '@/models/Measurement/Measurement';
import ClientoDialogSelect from '@valtrem/cliento-vuetify-dialog-select/src/components/DialogSelect.vue';
export default Vue.extend({
  name: 'MeasurementSelect',
  data: () => ({
    query: ''
  }),

  watch: {},
  props: {
    value: {
      type: String
    },
    disabled: {
      type: Boolean
    }
  },
  components: {
    ClientoDialogSelect
  },
  computed: {
    ...mapGetters({
      getAllMeasurements: 'measurements/all'
    }),
    measurements() {
      return this.getAllMeasurements().map((p: Measurement) => p.title);
    }
  },
  methods: {
    handleUpdateQuery(query: string) {
      this.query = query;
    }
  }
});
